<template>
  <div class="volumeSet">
    <el-dialog
        :title='$t("Add")'
        :visible.sync="modelShow"
        width="500px"
        fullscreen
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :show-close="false"
        :append-to-body="true">
      <div class="content">
        <el-form ref="form" :model="form" label-width="80px" :rules="rules">
          <el-form-item label="名称" prop="name">
            <el-input v-model="form.name"></el-input>
          </el-form-item>
          <el-form-item label="编码" prop="code">
            <el-input v-model="form.code"></el-input>
          </el-form-item>
          <el-form-item label="功率" prop="power">
            <el-input v-model="form.power" type="number"></el-input>
          </el-form-item>
          <el-form-item label="设备类型"  prop="type">
            <el-select v-model="form.type" placeholder="请选择">
              <el-option
                  v-for="item in options"
                  :key="item.code"
                  @click.native="getDTO(item)"
                  :label="item.name"
                  :value="item.code">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="工序"  prop="processId">
            <el-select v-model="form.processId" placeholder="请选择">
              <el-option
                  v-for="item in process"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
<!--          <el-form-item label="是否启用">-->
<!--            <el-radio-group v-model="form.name">-->
<!--              <el-radio :label="1">是</el-radio>-->
<!--              <el-radio :label="2">否</el-radio>-->
<!--            </el-radio-group>-->
<!--          </el-form-item>-->
        </el-form>
      </div>
<!--      内存dialog-->
      <p slot="footer" class="dialog-footer" style="margin-top: -30px;">
        <el-button @click="closeModel">{{$t("Cancel")}}</el-button>
        <el-button type="primary" @click="makeSure">{{$t("Yes")}}</el-button>
      </p>
    </el-dialog>
  </div>
</template>

<script>

export default {
  name: "edit",
  props: {
    modelShow: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      form:{
        name:'',
        code:'',
        power:'',
        dictDTO:'',
            processId: '',
            type:'',
      },
      options:[],
      process:[],
      rules: {
        name: [
          { required: true, message: '请输入名称', trigger: 'blur' },
        ],
        code: [
          { required: true, message: '请输入编码', trigger: 'blur' },
        ],
        power: [
          { required: true, message: '请输入功率', trigger: 'blur' },
        ],
        type: [
          { required: true, message: '请选择设备类型', trigger: 'blur' },
        ],
        processId: [
          { required: true, message: '请选择工序', trigger: 'blur' },
        ],
      }
    }
  },
  created(){
    this.getProse()
    this.getType()
  },
  methods: {
    closeModel() {
      this.$emit('closeModel', false)
    },
    async getProse(){
      const  res = await this.request.get(this.proApis.PROCESSSELECTALL)
      console.log(res,'工序')
      this.process = res.data

    },
    getDTO(val) {
        this.form.dictDTO = val
    },
    async getType(){
      const  res = await this.request.get(this.proApis.SELECTDICTBYCATEGORYID+'?categoryId=machineType')
      console.log(res)
      this.options = res.data.data
    },
    makeSure() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.$emit('addDev', this.form)
        } else {
          console.log('error submit!!');
          return false;
        }
      });

    },
  }
}
</script>

<style lang="scss" scoped>
.dialog-footer {
  width: 100%;
  //text-align: center;
}
</style>